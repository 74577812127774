@import "backgrounds/images";
@import "backgrounds/gradients";
@import "backgrounds/colors";
@import "backgrounds/sections";

.bg-none{
  background: none;
}

.bg-light-85{
  background-color: rgba($light, 0.85);
}

.bg-light-75{
  background-color: rgba($light, 0.75);
}

.bg-light-50{
  background-color: rgba($light, 0.5);
}

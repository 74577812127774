
.news-ticker {

  &:hover{
    cursor: pointer;
  }
}



.news-ticker-1 {
  .news{
    background-color: $white;
  }
}

.news-ticker {
  overflow: hidden;
  margin: 0 auto;

  .title {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }

  .news {

  }
}

.news-content {
  display: block;

  .news-content-item {
    padding-left: 10px;
    padding-right: 30px;
    > * {
      display: inline-block;
      margin: 0;
    }
  }

  .news-content-icon {
    margin-right: 10px;
  }

  .js-marquee > * {
    display: inline-block;
    margin: 0;
  }
}


@import "newsticker/light";


:root {

  --theme-text-heading-color: var(--bs-primary, #{$primary});
  --theme-nav-main-light-background: var(--bs-light);

}

.theme-light {

  h1,.h1{
    color: var(--theme-text-heading-color, #{$primary});
    font-weight: var(--theme-text-heading-weight, #{$headings-font-weight});
  }

  h2,.h2{
    color: var(--theme-text-heading-color, #{$primary});
    font-weight: var(--theme-text-heading-weight, #{$headings-font-weight});

  }

  h3,.h3{
    color: var(--theme-text-heading-color, #{$primary});
    font-weight: var(--theme-text-heading-weight, #{$headings-font-weight});
  }

  h4,.h4{
    color: var(--theme-text-heading-color, #{$primary});
    //font-weight: var(--theme-text-heading-weight, #{$headings-font-weight});
  }

  h5,.h5{
    color: var(--theme-text-heading-color, #{$primary});
    //font-weight: var(--theme-text-heading-weight, #{$headings-font-weight});
  }

  h6,.h6{
    color: var(--theme-text-heading-color, #{$primary});
  }

  .btn-primary {
    background-color: var(--bs-primary, #{$primary});
  }

  .btn-secondary {
    background-color: var(--bs-primary, #{$secondary});
  }
}

.navbar-header {
  background-color: red;
  background-image: url('../img/header/header-small.jpg');
}

.header {

  .headline{
    margin-bottom: .175rem;
  }

  .sub-headline{
    color: #9d9c9c;
  }
}

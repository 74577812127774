
//
//
//.navbar-brand-hidden{
//  display: none;
//}
//
//.navbar-brand-show-icon-only{
//  .navbar-brand-title {
//    display: none;
//  }
//}
//
//.navbar-brand-show-title-only{
//  .navbar-brand-icon {
//    display: none;
//  }
//}
//
//
//.navbar-brand-show-image-only{
//  .navbar-brand-title {
//    display: none;
//  }
//  .navbar-brand-icon {
//    display: none;
//  }
//}
//
//
//
//
//



#navbarMain{
  //border-bottom: solid 4px var(--bs-asbAnthrazit)
  border-bottom: solid 0px var(--bs-asbRed)
}













// Swiper


.swiper-content-wrapper {

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;


  }


  .content-box {
     //position: absolute;
    background-color: rgba(255,255,255,.0);
    width: 500px;

    @extend .p-5;
    font-weight: 700;
    @extend .h3;
    z-index: 1000;


    &.content-box-left{
      margin-left: -30%;
    }
  }

}



.accordion-button:not(.collapsed) {

  background-color: var(--bs-primary-light);
  background-color: var(--bs-primary-dark);
}

.slogan {
  @extend .h4;
  font-weight: 400 ;
}

.bg-asb-red {
  background-color: $asbRed !important;
}

.bg-asb-red-dark {
  background-color: $asbRedDark !important;
}

.bg-gradient-56 {
  background-image: linear-gradient(120deg, $asbRed 0%, $asbRedDark 100%);
}


.bg-gradient-57 {
  background-image: linear-gradient(180deg, $asbRed 0%, $secondary 100%);
}

.bg-asb-yellow {
  background-color: $asbYello !important;
}

.bg-asb-yellow-dark {
  background-color: $asbYelloDark !important;
}


.bg-asb-ant {
  background-color: $asbAnthrazit !important;
}

.bg-asb-ant-dark {
  background-color: $asbAnthrazit !important;
}




.card-border-bottom-red{
  border-bottom: solid 5px $asbRed !important;
}

.card-border-bottom-ant{
  border-bottom: solid 5px $asbAnthrazit !important;
}


.card-kacheel-hover-shadow {

  transition: all 0.25s ease;

  &:hover{
    transition: all 0.25s ease-in-out;
    .card-title {
      background-color: $asbRed;
      color: $white;

      transition: all 0.25s ease;
    }

  }
}

/* begin custom-root-vars */
:root {
  --theme-footer-heading-color: var(--bs-primary, #{$primary});
  --theme-footer-copyright-background: var(--bs-primary, #{$primary});
}
/* end custom-root-vars */












.bg-image-footer-box-1{
  background-image: url("../img/bg/earth.png");
  background-size: 92%;
}


.footer {
  background-color: var(--bs-light);

  .footer-section {

  }

  .footer-section-social {

  }

  .footer-section-main {

  }

  .footer-copyright {
    background-color: var(--theme-footer-copyright-background);

  }
}

.card-title {
  margin-bottom: 1.5rem;
}
.card-link * {
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-style: none !important;
}
a.card-link  {
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-style: none !important;

  &:hover {
    @extend .shadow;
  }
}


.btn-card {
  @extend .border-none;
  @extend .shadow-none;
  &:hover {

  }
}



@include media-breakpoint-only(xs) {
    .card-logo-image {
      --bs-aspect-ratio: 50%; // 2x1
    }
}

@import "card/primary";
@import "card/secondary";
@import "card/light";
@import "card/dark";

.card {


  &.border-none{
    border: none;
    .card-footer{
      border: none;
    }
    .card-header{
      border: none;
    }
  }

  .card-clean {

  }

  &.card-hover-shadow{
    border: none;
    padding: 15px;
    &:hover {
      @extend .shadow;
    }
  }
}

.card-footer {
  background: none;
}

.card-simple-list-item {
  text-decoration: none;
  color: $body-color;
  //line-height: 1.15rem;
  @extend .small;
  a {
    text-decoration: none;
  }
  &:hover{
    @extend .shadow;
  }
}
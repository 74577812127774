:root {
  --theme-navbar-main-primary-text-color: #{$white}
}
.navbar {

  &.navbar-primary {
    background-color: var(--bs-primary);
    .navbar-brand {
      color: var(--theme-navbar-main-primary-text-color);
    }
    .navbar-nav{
      > .nav-item {
        > .nav-link {
          color: var(--theme-navbar-main-primary-text-color);
        }
      }
    }
  }
}
.overlay{
  position: relative;
  overflow: hidden;
  display: block;

    
   
  .overlay-layer {
    width: 100%;
    position: absolute;
    top:0;
    @extend .h-100;
  }






  &:hover {

    .overlay-hover{
       .overlay-intro {
          opacity: 1;
          top: 0%;
          background-color: rgba(0,0,0,0.35);
          transition: background-color 0.25s ease-in, opacity 0.25s ease-in;

          .overlay-intro-content {
            top: 30%;
            opacity: 1;
          }
        }
    }

  



    .overlay-layer-1 {

    }


   
    .overlay-layer-2 {
      
    }


   
    .overlay-layer-3 {
      
    }

  }

  &.overlay-hover {

    .overlay-intro {
      opacity: 0;
      background-color: rgba(0,0,0,0);
      top: 0%;
    }

    .overlay-intro-content {
      top: 0%;
    }

  }

  .overlay-intro {
    display: block;
    position: absolute;
    top: 0;

   
    min-height: 100%;
    height: 100%;
    width: 100%;
    
    
    //transition: top 0.15s cubic-bezier(.22,.61,.36,1), background-color 0.15s cubic-bezier(.22,.61,.36,1);
    transition: background-color 0.25s ease-out, opacity 0.25s ease-out;

    .overlay-icon{
      font-size: 3rem;
    }

    .overlay-intro-content {
      @extend .p-3;
      position:absolute;
      top: 100%;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: opacity .5s cubic-bezier(.22,.61,.36,1), top .5s cubic-bezier(.22,.61,.36,1);
    }

  }
}

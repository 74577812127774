ul > li {
  margin-bottom: 0.5rem;
}
.ul-1 {
  // simple
  list-style: none;
  padding-left: 20px;
  > li {
    //margin: 1.5rem 0;
    position: relative;

    &:before{
      color: red;
      //content: " ";
      //background-size: cover;
      content: url("../img/html/ul-arrow-1.svg");
      position: absolute;
      left: -1.4rem;
      top: .125rem;
    }
  }
}



.ul-1 {
  // simple
  list-style: none;
  padding-left: 20px;
  > li {
    position: relative;

    &:before{
      color: red;
      //content: " ";
      //background-size: cover;
      content: url("../img/html/ul-arrow-2.svg");
      position: absolute;
      left: -1.4rem;
      top: .125rem;
    }
  }
}


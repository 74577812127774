.navbar {

  &.navbar-small {

    .dropdown-item{
      font-size: 0.95rem;

    }
    .nav-item {
      font-size: 0.95rem;
    }

    .dropdown-item-text {
      padding: 0;
    }

  }
}

.simply-countdown {
  /* The countdown */
}
.simply-countdown > .simply-section {
  /* coutndown blocks */
}

.simply-countdown > .simply-section > div {
  /* countdown block inner div */
}

.simply-countdown > .simply-section .simply-amount,
.simply-countdown > .simply-section .simply-word {
  /* amounts and words */
}

.simply-countdown > .simply-section .simply-amount {
  /* amounts */
}

.simply-countdown > .simply-section .simply-word {
  /* words */
}




.countdown {

  .countdown-count{
    font-size: $h1-font-size;
    font-weight: bold;
  }

  .countdown-item {
    @extend .justify-content-center;
    @extend .rounded ;
    text-align: center;
    display: flex;

    &.countdown-item-card{
      @extend .card;
    }

    .countdown-item-header{
      @extend .card-header;
    }

    .countdown-item-footer{
      @extend .card-footer;
    }

    .countdown-item-body{
      @extend .card-body;
      @extend .align-self-center;
    }

    .countdown-item-icon{
      font-size: 4.5rem;
      line-height: 4.5rem;
      @extend .mb-4;
    }

    .countdown-item-count{
      @extend .h1;
      @extend .mb-2;
      line-height: 100%;
      font-weight: normal;
    }

    .countdown-item-label {

    }
  }

  &.countdown-item-shadow-sm{
    .countdown-item{
      @extend .shadow;
      @extend .shadow-sm;
    }
  }

  &.countdown-item-shadow-lg{
    .countdown-item{
      @extend .shadow;
      @extend .shadow-lg;
    }
  }



  .countdown-item-light {
    @extend .bg-light;
    .countdown-item-icon {
      color: $primary;
    }
    .countdown-item-count {
      color: $primary;
    }
  }

  .countdown-item-primary {
    @extend .bg-primary;
    .countdown-item-header{
      color: $white;
      border-bottom-color: $white;
    }

    .countdown-item-icon {
      color: $white;
    }
    .countdown-item-count {
      color: $white;
    }
  }


}






.anchor-navigation {

  top: 59px;

  background-color: $light;




  &.anchor-navigation-with-main{
    top: 58px;
  }

  .nav-tabs{
    border-bottom: none;
  }
  .nav-link{
    background: none;
    border-width: 0px;
    border-bottom: 3px solid $light;
    color: var(--bs-body-color);
    transition: all 0.25s ease-out;

    &.active{
      background: none;
      border-width: 0px;
      color: $primary;
      border-bottom: 3px solid $primary;
      transition: all 0.25s ease-in;
    }
  }


  // swiper
  .swiper{
    //display: flex;
    .swiper-wrapper{
      flex-wrap: nowrap;
      white-space: nowrap;
      margin: 0;

      .swiper-slide{
        width: auto !important;
      }
    }

  }


  &.anchor-navigation-primary{
    background-color: var(--bs-primary);
    .nav-link{
      color: var(--bs-light);
      border-color: var(--bs-primary);
      &.active{
        border-color: var(--bs-light) !important;
      }
    }

  }

}


.section-anchor, .achor-sticky{
  scroll-margin-top: 70px;
}


#navbarMain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1000;
  border-top: 1px solid $white;
  border-bottom: 1px solid $border-color;

  @include transition(background-color .15s ease-in-out, box-shadow .15s ease-in-out);




  .navbar-nav {

    .dropdown-menu{
      padding: 0;
      border-width: 0px;
      margin-top: 1px;
      a.dropdown-item {
        padding: 0.5rem 1rem
      }
    }

    // erste ebene
    > .nav-item {

      &.dropdown:hover{
        > .nav-link {
          //background-color: red;
        }
      }
      //
      > .nav-link {
        padding: 15px;
        // color: $co-gray;

        &:hover{
          //background-color: red;
        }

        &.active{
          //background-color: red;
        }

        > .nav-link-border {
          height: 2px;
          //background-color: red;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }

      &.active {
        > .nav-link {
          //
        }
      }
    }
  }
}


@import "navbar/navbar-small";
@import "navbar/navbar-light";
@import "navbar/navbar-primary";

.js-sticky{
  border-bottom: none !important;
}

.dropdown-divider{
  margin: 0 !important;
}
.user-menu {
  padding: 0;
  margin: 0 !important;
  .user-name {
    font-size: 0.9rem
  }
}

.dropdown-item:active {
  background-color: var(--bs-light);
}

//:after {
//  content: '';
//  background-color: #ffffff;
//  display: block;
//  height: 2px;
//  margin: 0.325em 0 0.5em 0;
//  width: 100%;
//}

.dynamic-text-shadow {
  /*  larger font size, more shadow distance  */
  text-shadow: .15rem .15rem 0px hsla(200, 50%, 30%, 0.25);
}

//.stroked:after {
//  content: '';
//  position: absolute;
//  left: 0;
//  width: 3.5rem;
//  height: 0.1rem;
//  background-color: var(--bs-primary);
//}
//
//h3.stroked::after {
//
//  bottom: -1.325rem;
//
//}

@include media-breakpoint-up(md) {
  .text-justify {
    word-spacing: 2px;
    text-align: justify;
    text-justify: distribute;
  }
}

.section {

}

.section-header-outside {
  position: relative;
  //border-bottom: solid 6px #fff;
  background-color: var(--bs-primary);
  * {
    color: #fff;
  }
}

.section-1 {

  .header{
    @extend .px-5;
    @extend .py-5;
  }

  .content{
    @extend .px-5;
    @extend .py-5;
    background-color: $white;
  }
}

@include media-breakpoint-up(lg) {
  .bla{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: auto 100vh;
    background-position: 50% 0;
  }

  .section-1 {

    .header{
      text-align: right;
      background-attachment: fixed;
    }

    .content{
    }
  }
}


.section-banner{

}


@import "section/primary";
@import "section/secondary";
@import "section/light";
@import "section/dark";
.section-bg-1{
  @extend .bg-image;
  background-image: url("../img/bg/background-1.jpg");
  color: #fff;



  a {
    color: $orange;
  }

  h1,h2,h3,h4,h5 {
    color: $orange;
  }
}

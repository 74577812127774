.page-header{
  position: relative;
  margin: 0px;
  padding: 0px;
  display: block;

  .page-header-content{
    //@extend .justify-content-center;
    //@extend .align-items-center;
    @extend .h-100;
    //@extend .d-flex;
    //@extend .flex-column;
    padding: 0;
  }

  .scroll-down {
    position:absolute;
    bottom: 3rem;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    //background-color: #fff;
    display: block;
    cursor:pointer;
  }

}

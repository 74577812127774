:root {
  --theme-card-primary-text-color: #{$light};
}


.card-primary {
  @extend .border-none;

  .card-subtitle{
    &.text-muted{
      color: var(--theme-card-primary-text-color, #{$light}) !important;
    }
  }
  .card-title{
    .h4{
      color: var(--theme-card-primary-text-color, #{$light});
    }
  }
  .card-body{
    color: var(--theme-card-primary-text-color, #{$light});
    a{
      color: var(--theme-card-primary-text-color, #{$light});
    }
  }

  background-color: var(--bs-primary, #{$primary});
  * {

  }

  .card-foorter{

  }
  .btn-card-primary{
    //border: none;

    @extend .btn-light;
  }
}
.footer-section-social{

}

@include media-breakpoint-up(lg) {

  .footer-section-social {

    position: fixed;
    right: 0;
    bottom: 15px;
    background-color: var(--bs-body-bg);
    width: 50px;
    > .container-xl{
      padding: 0;
    }

  }
}

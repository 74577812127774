:root {
  --theme-section-primary-text-color: #{$white};
  //--theme-section-primary-background:  #{$primary};
}

.section-primary {
  background-color: var(--theme-section-primary-background, $primary);
  color: var(--theme-section-primary-text-color);

  .text-muted {
    color: var(--theme-section-primary-text-color) !important;
  }
}
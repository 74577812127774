.bg-gradient-1{
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.bg-gradient-2 {
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.bg-gradient-3 {
  background-image: linear-gradient(135deg, #ABDCFF 10%, $primary 100%);
}

.bg-gradient-4{
  background-image: linear-gradient(147deg, rgb(255, 229, 59) 0%, rgb(255, 37, 37) 74%);
}

.bg-gradient-5 {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

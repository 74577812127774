.f-grayscale-50 {
  filter: grayscale(50%);
  @include transition(filter 0.7s ease-in-out);
  &.animate{
    filter: grayscale(100%);

  }
}

.f-grayscale {
  filter: grayscale(100%);
}

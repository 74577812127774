
.section-primary {
  .ce-pages-cards {
    .card-title {
      color: var(--bs-primary, $primary);
    }
    .text-muted {
      color: var(--bs-body-color, $body-color) !important;
    }
    .card-text {
      color: var(--bs-body-color, $body-color) !important;
    }
  }
}

.section-dark {
  .ce-pages-cards {
    .card-title {
      color: var(--bs-primary, $primary);
    }
    .text-muted {
      color: var(--bs-body-color, $body-color) !important;
    }
    .card-text {
      color: var(--bs-body-color, $body-color) !important;
    }
  }
}


.section-light {
  .ce-pages-cards {
    .card-title {
      color: var(--bs-primary, $primary);
    }
    .text-muted {
      color: var(--bs-body-color, $body-color) !important;
    }
    .card-text {
      color: var(--bs-body-color, $body-color) !important;
    }
  }
}



.section-secondary {
  .ce-pages-cards {
    .card-title {
      color: var(--bs-primary, $primary);
    }
    .text-muted {
      color: var(--bs-body-color, $body-color) !important;
    }
    .card-text {
      color: var(--bs-body-color, $body-color) !important;
    }
  }
}





// Vars from Webpack config
$imgUrl: "/assets/img";
$fontUrl: "/assets/fonts";

@use "../node_modules/@fontsource/open-sans/scss/mixins" as OpenSans;
@use "../node_modules/@fontsource/asap/scss/mixins" as Asap;
@use "../node_modules/@fontsource/roboto/scss/mixins" as Roboto;


//@include OpenSans.fontFaceVariable($weight: 300, $type: "full");
//@include OpenSans.fontFaceVariable($weight: 400, $type: "full");
//@include OpenSans.fontFaceVariable($weight: 500, $type: "full");
//@include OpenSans.fontFaceVariable($weight: 600, $type: "full");
//@include OpenSans.fontFaceVariable($weight: 700, $type: "full");
//@include OpenSans.fontFaceVariable($weight: 800, $type: "full");


//
@include OpenSans.fontFace($weight: 300, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
@include OpenSans.fontFace($weight: 400, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//@include OpenSans.fontFace($weight: 500, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//@include OpenSans.fontFace($weight: 600, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
@include OpenSans.fontFace($weight: 700, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//@include OpenSans.fontFace($weight: "800", $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//
@include Asap.fontFace($weight: 400, $style: normal, $fontDir: $fontUrl + "/asap/files");
@include Asap.fontFace($weight: 600, $style: normal, $fontDir: $fontUrl + "/asap/files");
@include Asap.fontFace($weight: 700, $style: normal, $fontDir: $fontUrl + "/asap/files");
//
//@include Roboto.fontFace($weight: 100, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//@include Roboto.fontFace($weight: 300, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
@include Roboto.fontFace($weight: 300, $style: normal, $fontDir: $fontUrl + "/roboto/files");
@include Roboto.fontFace($weight: 400, $style: normal, $fontDir: $fontUrl + "/roboto/files");
//@include Roboto.fontFace($weight: 500, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
//@include Roboto.fontFace($weight: 600, $style: normal, $fontDir: $fontUrl + "/open-sans/files");
@include Roboto.fontFace($weight: 700, $style: normal, $fontDir: $fontUrl + "/roboto/files");
//@include Roboto.fontFace($weight: 800, $style: normal, $fontDir: $fontUrl + "/open-sans/files");



// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

@import "variables";
@import "variables-theme";

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap";
//@import "../node_modules/bootstrap/scss/root";
//@import "../node_modules/bootstrap/scss/reboot";
//@import "../node_modules/bootstrap/scss/type";
//@import "../node_modules/bootstrap/scss/images";
//@import "../node_modules/bootstrap/scss/containers";
//@import "../node_modules/bootstrap/scss/grid";

// 5. Add additional custom code here

@import 'reset';








.object-fit-cover{
  object-fit: cover;
}





.bg-image{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.aspect-ratio-4-3{
    position: relative;
    width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
}

.aspect-ratio-16-9{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}



.go-bottom{
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
}



@import "page";
@import "filter";
@import "backgrounds";

@import "text";
@import "header";
@import "section";


@import "list";
@import "components";
@import "extensions";


.ratio-img{
  overflow: hidden;
  position: relative;
  display: block;
  > img {
    height: 100% ;
    width: auto;
    display: block;
  }
}

.img-hover-zoom{
  overflow: hidden;
  position: relative;
  display: block;

  img{
    height: auto !important;
    //width: 100%;
    //min-width: 100%;

    transition: transform 0.3s ease, filter 0.3s ease ;
    display: block;

    &:hover{
      transform: scale(1.175);
      filter: hue-rotate(180deg);
    }
  }
}

:root {
  --swiper-theme-color: var(--bs-primary);
  --swiper-navigation-color: var(--bs-primary);
  --theme-border-color:  #{$border-color};
}

.bg-fixed{
  background-attachment: fixed;
}

.main-content{
  display: flex;
  flex: 1 0 auto;
}

@import "content-element/pages";
@import "content-element/call-to-action";
@import "socials";
@import "custom";



.collage {

  border-bottom: 6px solid $asbRed;

  a{
    .ratio{


      img {
        //filter: grayscale(70%);
      }

      .content-layer {
        display: none;
      }

    }

    &:hover {
      .ratio{
        img {
          //filter: grayscale(0%);
        }
        .content-layer {

          display: flex;
          padding: 15px;
          background-color: rgba(0,0,0,0.5);
          color: white;
        }
      }
    }
  }


}


#navbar-content {
  .nav-item {
    .nav-link {
      color: $asbAnthrazit;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      &:hover {
        color: $asbAnthrazit;
      }
    }
  }
}

:root {
  //--theme-service-item-primary-bg: #{$border-color};
  --theme-service-item-primary-title-color: #{$white};
  --theme-service-item-primary-text-color: #{$white};

  //--theme-service-item-secondary-bg: #{$secondary};
  --theme-service-item-secondary-text-color: #{$white};
  --theme-service-item-dark-text-color: #{$white};
}

a.service-item * {
  text-decoration: unset !important;
}

.service-item {
  @extend .card;
  @extend .h-100;
  text-align: center;



  text-decoration: none !important;


  .service-item-icon {
    font-size: $h1-font-size * 1.5;
    font-weight: bold ;
  }

  .service-item-title {
    font-size: $h3-font-size;
    font-weight: 400;
    @extend .pb-3;
  }

  .service-item-text {
    @extend .pb-3;
  }

  &:hover {
    @extend .shadow;
  }
}

.service-item-light{
  background-color: var(--theme-service-item-light-background, #{$light});
  .service-item-icon {
    color: var(--theme-service-item-light-icon-color, #{$primary});
  }
  .service-item-title {
    color: var(--theme-service-item-light-title-color, #{$primary});
  }
  .service-item-text {
    color: var(--theme-service-item-light-text-color, #{$light});
  }
}


.service-item-dark{
  background-color: var(--theme-service-item-dark-background);
  .service-item-icon {
    color: var(--theme-service-item-dark-icon-color);
  }
  .service-item-title {
    color: var(--theme-service-item-dark-title-color);
  }
  .service-item-text {
    color: var(--theme-service-item-dark-text-color);
  }
}



.service-item-primary{
  background-color: var(--theme-service-item-primary-background);
  .service-item-icon {
    color: var(--theme-service-item-primary-icon-color);
  }
  .service-item-title {
    color: var(--theme-service-item-primary-title-color);
  }
  .service-item-text {
    color: var(--theme-service-item-primary-text-color);
  }
}

.service-item-secondary {
  background-color: var(--theme-service-item-secondary-background);
  color: var(--theme-service-item-secondary-text-color);
  .service-item-icon {
    color: var(--theme-service-item-secondary-icon-color);
  }
  .service-item-title {
    color: var(--theme-service-item-secondary-title-color);
  }
  .service-item-text {
    color: var(--theme-service-item-secondary-text-color);
  }
}
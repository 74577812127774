.cookie-consent-container {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  font-family: Helvetica Light, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #4d4d4d;
  line-height: 1.5;

  .detail {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    //transition: all .5s ease-in-out;

  }
}



.cookie-consent-container .detail.open {
  height: auto;
  width: 100%;
  visibility: visible;
  opacity: 1;
  overflow: auto;
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
  //transition: all .5s ease-in-out
}

.cookie-consent-container .text-center {
  text-align: center
}

.cookie-consent-container p {
  margin: 0 0 15px 0
}

.cookie-consent-container a, .cookie-consent-container a:visited, .cookie-consent-container a:active, .cookie-consent-container a:focus, .cookie-consent-container a:focus:active {
  color: #999;
  text-decoration: none
}

.cookie-consent-container a:hover, .cookie-consent-container a:visited:hover, .cookie-consent-container a:active:hover, .cookie-consent-container a:focus:hover, .cookie-consent-container a:focus:active:hover {
  color: #000;
  //transition: all .5s ease-in-out
}

.cookie-consent-container a.show-details, .cookie-consent-container a:visited.show-details, .cookie-consent-container a:active.show-details, .cookie-consent-container a:focus.show-details, .cookie-consent-container a:focus:active.show-details {
  display: block;
  position: relative;
  padding-right: 20px;
  font-size: .75rem;
  margin-bottom: 25px
}

@media all and (min-width: 660px) {
  .cookie-consent-container a.show-details, .cookie-consent-container a:visited.show-details, .cookie-consent-container a:active.show-details, .cookie-consent-container a:focus.show-details, .cookie-consent-container a:focus:active.show-details {
    margin-bottom: 15px
  }
}

.cookie-consent-container a.show-details:after, .cookie-consent-container a:visited.show-details:after, .cookie-consent-container a:active.show-details:after, .cookie-consent-container a:focus.show-details:after, .cookie-consent-container a:focus:active.show-details:after {
  content: "";
  width: 0;
  height: 0;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  //border-style: solid;
  //border-width: 4px 4px 0 4px;
  //border-color: #999 transparent transparent transparent;
  margin: 7px 0 0 5px;
  position: absolute;
  top: 0
}

.cookie-consent-container a.show-details:hover:after, .cookie-consent-container a:visited.show-details:hover:after, .cookie-consent-container a:active.show-details:hover:after, .cookie-consent-container a:focus.show-details:hover:after, .cookie-consent-container a:focus:active.show-details:hover:after {
  //transition: all .5s ease-in-out;
  //border-color: #000 transparent transparent transparent
}

.cookie-consent-container a.show-details .less, .cookie-consent-container a:visited.show-details .less, .cookie-consent-container a:active.show-details .less, .cookie-consent-container a:focus.show-details .less, .cookie-consent-container a:focus:active.show-details .less {
  display: none
}

.cookie-consent-container a.show-details.open .more, .cookie-consent-container a:visited.show-details.open .more, .cookie-consent-container a:active.show-details.open .more, .cookie-consent-container a:focus.show-details.open .more, .cookie-consent-container a:focus:active.show-details.open .more {
  display: none
}

.cookie-consent-container a.show-details.open .less, .cookie-consent-container a:visited.show-details.open .less, .cookie-consent-container a:active.show-details.open .less, .cookie-consent-container a:focus.show-details.open .less, .cookie-consent-container a:focus:active.show-details.open .less {
  display: inline-block
}

.cookie-consent-container a.show-details.open:after, .cookie-consent-container a:visited.show-details.open:after, .cookie-consent-container a:active.show-details.open:after, .cookie-consent-container a:focus.show-details.open:after, .cookie-consent-container a:focus:active.show-details.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(1800deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.cookie-consent-container .label {
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 300;
  display: inline-block;
  margin: 0 0 12px 0;
  position: relative;
  padding: 0 0 0 30px;
  line-height: 1.5;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.cookie-consent-container .label.partially-checked .checkmark {
  background-color: $primary
}

.cookie-consent-container .label.partially-checked .checkmark:after {
  display: none
}

.cookie-consent-container .label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0
}

.cookie-consent-container .label input:checked ~ .checkmark {
  background-color: $primary
}

.cookie-consent-container .label input:checked ~ .checkmark:after {
  display: block
}

.cookie-consent-container .label input:disabled:checked ~ .checkmark {
  opacity: .6
}

.cookie-consent-container .label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: #eee;
  //border: 1px solid #bfbfbf
}

.cookie-consent-container .label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 0;
  width: 7px;
  height: 15px;
  //border: solid #fff;
  //border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.cookie-consent-container .consent-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 950px;
  width: 90%;
  height: 590px;
  max-height: calc(100vh - 50px);
  //min-height: 295px;
  margin: auto;
  @extend .p-5;
  background-color: #fff;
  overflow-y: auto;
  //box-shadow: 0 0 5px #000;
  //border-radius: 5px;
  //transition: all .5s ease-in-on

  form {

    .options{
      margin-top: 30px;
      margin-bottom: 30px;
    }

  }
}

@media all and (min-width: 500px) {
  .cookie-consent-container .consent-modal {
    //height: 460px
  }
}

@media all and (min-width: 900px) {
  .cookie-consent-container .consent-modal {
    //height: 448px;
    max-height: calc(100vh - 100px)
  }
}

.cookie-consent-container .consent-modal .option-wrapper {
  background: #eee;
  padding: 15px;
  margin-bottom: 15px
}

.cookie-consent-container .consent-modal .option-wrapper:last-child {
  margin-bottom: 0
}

.cookie-consent-container .consent-modal .option-wrapper p:last-child {
  margin-bottom: 0
}

.cookie-consent-container .consent-modal .option-wrapper .option-headline {
  text-transform: uppercase;
  //letter-spacing: 1px;
  font-size: 1.25rem;
  font-weight: 400
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper {
  margin-bottom: 10px;
  //border-bottom: 2px solid #afafaf
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper:last-child {
  margin-bottom: 0
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail {
  //border-bottom: 1px solid #ddd;
  padding: 7px 10px 3px;
  font-size: .875rem
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail:last-child {
  //border-bottom: none;
  padding-bottom: 6px
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail.cookie-option {
  background: #dedede;
  font-size: 1rem;
  font-weight: 400;
  //transition: all ease-in-out .5s
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail.cookie-option:hover {
  background: #d4d0d0;
  //transition: all ease-in-out .5s
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail.cookie-option:hover ~ .option-detail {
  background: #f3f3f3;
  //transition: all ease-in-out .5s
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail.cookie-option .label {
  color: #4d4d4d;
  text-align: left;
  font-weight: 400
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail .option-title {
  font-weight: 400;
  width: 100%;
  text-align: left;
  display: inline-block;
  vertical-align: top
}

@media all and (min-width: 500px) {
  .cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail .option-title {
    width: 30%
  }
}

.cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail .option-value {
  width: 100%;
  display: inline-block;
  vertical-align: top
}

@media all and (min-width: 500px) {
  .cookie-consent-container .consent-modal .option-wrapper .option-detail-wrapper .option-detail .option-value {
    width: 69%
  }
}

.cookie-consent-container .consent-modal .option-wrapper .label {
  margin-bottom: 0;
  padding: 0 20px 0 0;
  width: 100%
}

.cookie-consent-container .consent-modal .option-wrapper .label .checkmark {
  right: 0;
  left: inherit;
  background: #eee;
  //border: 1px solid #bfbfbf
}

.cookie-consent-container .consent-modal .option-wrapper .label input:checked ~ .checkmark {
  background-color: $primary
}

.cookie-consent-container .consent-modal .cookie-headline {
  line-height: 1.5;
  margin: 0 0 15px 0;
  color: #4d4d4d;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.25rem;
  font-weight: 400
}

.cookie-consent-container .consent-modal.open {
  //max-width: 750px;
  height: calc(100vh - 50px)
}

.cookie-consent-container .consent-modal .options {
  list-style: none;
  margin: 0 0 15px 0;
  padding: 0
}

.cookie-consent-container .consent-modal .options li {
  margin-bottom: 5px
}

@media all and (min-width: 500px) {
  .cookie-consent-container .consent-modal .options li {
    //width: 49%;
    display: inline-block;

    margin-right: 60px;
  }
}

.cookie-consent-container .consent-modal .footer-links {
  display: block;
  width: 100%;
  line-height: 90%
}

.cookie-consent-container .buttons {
  margin-bottom: 30px
}

@media all and (min-width: 660px) {
  .cookie-consent-container .buttons {
    margin-bottom: 15px
  }
}

.cookie-consent-container .buttons .btn {
  @extend .btn;
  @extend .btn-lg;

  //font-size: 1rem;
  //padding: 11px 10px;
  cursor: pointer;
  //border-radius: 3px;
  //width: 100%;
  //max-width: 370px;
  //text-align: center;
  //font-weight: 300;
  //clear: both;
  //text-transform: uppercase;
  //letter-spacing: 1px;
  //line-height: 100%
}

.cookie-consent-container .buttons .btn:hover {
  //transition: all .5s ease-in-out
}

.cookie-consent-container .buttons .btn--primary {
  background: $primary;
  color: #fff;
  //border: 2px solid $primary;
  margin-bottom: 10px
}

.cookie-consent-container .buttons .btn--primary:hover {
  //background: #30400f;
  //border: 2px solid #30400f
}

.cookie-consent-container .buttons .btn--secondary {
  background: #eeeeee;
  color: #4d4d4d;
  //border: 2px solid #999;
  //opacity: .5;
  //background: $primary;
  margin-bottom: 10px;
  margin-left: 30px;
}

.cookie-consent-container .buttons .btn--secondary:hover {
  background: $primary;
  color: #fff;

  //opacity: 1
}

.cookie-consent-settings {
  margin-bottom: 10px
}

.cookie-consent-settings .detail {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  //transition: all .5s ease-in-out
}

.cookie-consent-settings .detail.open {
  height: auto;
  width: 100%;
  visibility: visible;
  opacity: 1;
  overflow: auto;
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
  //transition: all .5s ease-in-out
}


:root{
  --bs-text-columns-gap: 5rem;
  --bs-text-columns-rule-color: $primary;
}


.text-col {
  //column-count: 3 !important;
  column-gap: var(--bs-text-columns-gap);
  column-rule-style: solid;
  column-rule-width: 0px;
  column-rule-color: var(--bs-text-columns-rule-color);
  column-span: all;
}

.text-col-rw-1{
  column-rule-width: 1px;
}
.text-col-rw-2{
  column-rule-width: 2px;
}
.text-col-rw-3{
  column-rule-width: 3px;
}
.text-col-rw-4{
  column-rule-width: 5px;
}

@include media-breakpoint-only(sm) {
  .text-col-sm-1{
    column-count: 1 !important;
  }
  .text-col-sm-2{
    column-count: 2 !important;
  }
  .text-col-sm-3{
    column-count: 3 !important;
  }
  .text-col-sm-4{
    column-count: 4 !important;
  }
}



@include media-breakpoint-up(md) {
  .text-col-md-1{
    column-count: 1 !important;
  }
  .text-col-md-2{
    column-count: 2 !important;
  }
  .text-col-md-3{
    column-count: 3 !important;
  }
  .text-col-md-4{
    column-count: 4 !important;
  }
}

@include media-breakpoint-up(lg) {
  .text-col-lg-1{
    column-count: 1 !important;
  }
  .text-col-lg-2{
    column-count: 2 !important;
  }
  .text-col-lg-3{
    column-count: 3 !important;
  }
  .text-col-lg-4{
    column-count: 4 !important;
  }
  .text-col-lg-5{
    column-count: 5 !important;
  }
}
@include media-breakpoint-only(xl) {
  .text-col-xl-1{
    column-count: 1 !important;
  }
  .text-col-xl-2{
    column-count: 2 !important;
  }
  .text-col-xl-3{
    column-count: 3 !important;
  }
  .text-col-xl-4{
    column-count: 4 !important;
  }
  .text-col-xl-5{
    column-count: 5 !important;
  }
}
@include media-breakpoint-only(xxl) {
  .text-col-xxl-1{
    column-count: 1 !important;
  }
  .text-col-xxl-2{
    column-count: 2 !important;
  }
  .text-col-xxl-3{
    column-count: 3 !important;
  }
  .text-col-xxl-4{
    column-count: 4 !important;
  }
  .text-col-xxl-5{
    column-count: 5 !important;
  }
}

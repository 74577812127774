
$bootstrap-icons-font: "bootstrap-icons" !default;
$bootstrap-icons-font-src: url($fontUrl + "/bs/fonts/bootstrap-icons.woff2?a74547b2f0863226942ff8ded57db345") format("woff2"),
url($fontUrl + "/bs/fonts/bootstrap-icons.woff?a74547b2f0863226942ff8ded57db345") format("woff") !default;


@import "~bootstrap-icons/font/bootstrap-icons";
@import "text/text-columns";
@import "text/text-styles";
@import "text/text-shadows";

@import "glightbox/dist/css/glightbox.css";

@import "glightbox/src/postcss/glightbox.css";



/*Skin */
.glightbox-base {


  .goverlay{
    background-color: rgba(0,0,0,0.75);
  }



  .gslide-media {
    @media (--medium-small-viewport) {
      box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    }
  }

  .gslide-description {
    background: #fff;
  }

  .gdesc-inner {
    padding: 22px 20px;
  }

  @media (--medium-small-viewport) {
    .description-left,
    .description-right {
      .gdesc-inner {
        position: absolute;
        height: 100%;
        overflow-y: auto;
      }
    }
  }

  .gslide-title {
    font-size: 1em;
    font-weight: normal;
    font-family: arial;
    color: #000;
    margin-bottom: 19px;
    line-height: 1.4em;
  }

  .gslide-desc {
    font-size: 0.86em;
    margin-bottom: 0;
    font-family: arial;
    line-height: 1.4em;
  }

  .gslide-video {
    background: #000;
  }

  .gprev,
  .gnext,
  .gclose {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;

    @media (--medium-small-viewport) {
      background-color: rgba(0, 0, 0, 0.32);

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    path {
      fill: #fff;
    }
  }

  .gprev {
    position: absolute;
    //top: -100%;
    top: 45%;
    left: 30px;
    width: 40px;
    height: 50px;

    @media (--medium-small-viewport) {
      top: 45%;
    }
  }

  .gnext {
    position: absolute;
    //top: -100%;
    top: 45%;
    right: 30px;
    width: 40px;
    height: 50px;

    @media (--medium-small-viewport) {
      top: 45%;
    }
  }

  .gclose {
    width: 35px;
    height: 35px;
    top: 15px;
    right: 10px;
    position: absolute;

    svg {
      width: 18px;
      height: auto;
    }

    @media (--medium-viewport) {
      opacity: 0.7;
      right: 20px;
    }

    &:hover {
      opacity: 1;
    }
  }
}


.text-link:before{
  @extend .bi;
  @extend .bi-link;
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;

}

.ce-text {
  &:after{
    content: "";
    display: block;
    clear: both;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  _div {
    display: block;

    &.float-start {
      margin-right: 3rem;
      @include media-breakpoint-down (sm) {
        float: none;
        margin-bottom: 3rem;
        width: 100%;
        margin-right: 0;
      }
    }

    &.float-end{
      margin-left: 3rem;
      @include media-breakpoint-down (sm) {
        float: none;
        margin-bottom: 3rem;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &.ce-text-style-1{

  }

}

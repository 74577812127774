.bg-image-fixed{
  background-attachment: fixed;
}

.bg-image-1 > * {
  // color: $white !important;
  a {
    color: orange;
  }
}



.bg-image-1 {
  background-image: url("../img/bg/background-1.jpg");
}


.bg-image-2 {
  background-image: url("../img/bg/backgrounds.jpg");
}


.bg-image-3 {
  background-image: url("../img/bg/background-3.jpg");
}



.bg-image-67 {
  background-image: url("../img/theme/airplane-g25b48ead4_1920.jpg");
}

.bg-image-68 {
  background-image: url("../img/theme/woman-g7335ac950_1920.jpg");
}

.bg-image-68 {
  background-image: url("../img/header/home-header.jpg");
}

.bg-image-69 {
  background-image: url("../img/header/asb-jena-wuenschewagen.jpg");
}

.bg-image-69 {
  background-image: url("../img/baustelle/handyman-3094035_1920.jpg");
  background-position: center -33px;
}

.bg-image-70 {
  background-image: url("../img/baustelle/pixabay-people-1560569_1920.jpg");
}

.bg-image-kitas{
  background-image: url("../img/header/Saaleknirpse-1920-580.jpg");
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size:cover;
  z-index: -1;
}

//$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-size-base: 1rem;
$line-height-base: 1.75;
$font-family-sans-serif:      "Open Sans", "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$primary: #2d2d46 !default;
$secondary: #f5a000 !default;
$tertiary: #8cd2d2 !default;
$quaternary: #fdca00 !default;

$primaryLight: lighten($primary, 15%) !default;
$secondaryLight: lighten($secondary, 30%) !default;
$tertiaryLight: lighten($tertiary, 30%) !default;
$quaternaryLight: lighten($quaternary, 30%) !default;

$primaryDark: darken($primary, 15%) !default;
$secondaryDark: darken($secondary, 15%) !default;
$tertiaryDark: darken($tertiary, 15%) !default;
$quaternaryDark: darken($quaternary, 15%) !default;


$asbRed: #ca0a16; // #e30613;
$asbRedDark: darken($asbRed, 15%) !default;;
$asbYello: #ffee00;
$asbYelloDark: darken($asbYello, 15%) !default;;
$asbAnthrazit: #2d2d46;
$asbAnthrazitDark: darken($asbAnthrazit, 15%) !default;;;





$body-bg:                   #ffffff !default;
//$body-color:                #646464 !default;
$body-color:                $asbAnthrazit !default;
$border-color:              rgb(225, 225, 225) !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4.5,
) !default;


$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        600 !default;
$headings-line-height:        1.25 !default;

//$headings-color:              $primary !default;
//$headings-color-primary:      $primary !default;
//$headings-color-contrast:     #ff9f4b !default;

//$headings-margin-bottom:      $spacer * 1.75 !default;

//$border-color: #bebebe;

$enable-rounded: false !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-grid-classes: true !default;
$enable-caret: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;
$enable-smooth-scroll: true !default;


//
//$theme-colors: (
//        "primary":    $primary,
//        "secondary":  $secondary,
//        "success":    $success,
//        "info":       $info,
//        "warning":    $warning,
//        "danger":     $danger,
//        "light":      $light,
//        "dark":       $dark,
//        "asbRed": $asbRed,
//        "asbYello": $asbYello,
//        "asbAnthrazit": $asbAnthrazit
//) !default;





// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

$light:    $gray-100 !default;
$dark:     $gray-800 !default;
$success:  #28a745 !default;
$info:     #17a2b8 !default;
$warning:  #ffc107 !default;
$danger:   #dc3545 !default;


//$colors: (
//    "primary-light": lighten($primary, 30%),
//    "primary-dark": darken($primary, 30%),
//    "white": #fff,
//    "gray": $gray-600,
//    "gray-dark":  $gray-800
//);


$colors: (
    "primary": $primary,
    "seconday": $secondary,
    "tertiary": $tertiary,
    "quaternary": $quaternary,

    "primary-light": $primaryLight,
    "seconday-light": $secondaryLight,
    "tertiary-light": $tertiaryLight,
    "quaternary-light": $quaternaryLight,

    "primary-dark": $primaryDark,
    "seconday-dark": $secondaryDark,
    "tertiary-dark": $tertiaryDark,
    "quaternary-dark": $quaternaryDark,

    "white": #fff,
    "gray": $gray-600,
    "gray-dark":  $gray-800,

    "asbRed": $asbRed,
    "asbRed-dark": $asbRedDark,
    "asbYello": $asbYello,
    "asbAnthrazit": $asbAnthrazit
);


$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "asb-red": $asbRed,
    "asb-red-dark": $asbRedDark,
    "asb-yello": $asbYello,
    "asb-anthrazit": $asbAnthrazit
) !default;


$aspect-ratios: (
    "1x1": 100%,
    "3x2": calc(3 / 2 * 100%),
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "2x3": calc(2 / 3 * 100%),
    "3x4": calc(4 / 3 * 100%),
    "9x16": calc(16 / 9 * 100%),
    "9x21": calc(21 / 9 * 100%)
);



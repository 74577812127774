.tabs {

  //  .nav-tabs{
//    width: max-content;
//    .nav-item {
//      display: inline-block;
//    }
//  }
//
//  @include media-breakpoint-down(sm) {
//    .nav-tabs-scroll{
//      background-color: red;
//
//
//
//    }
//  }
//
//
//
//
//
//
//  .tab-pane {
//    border: solid 1px $nav-tabs-border-color;
//    border-top-width: 0px;
//  }
  .tab-pane-body {
    @extend .px-4;
    @extend .py-4;
  }

  @include media-breakpoint-down(sm) {
    .tab-pane-body {
      padding: 0;
    }
  }

}



// scroll navigation
.tabs{
  position:relative;
  width:100%;
  display:block;
  overflow: hidden;


  .nav-tabs {
    margin:0;
    padding:0;
    list-style:none;
    display: flex;
    flex-wrap: nowrap;
    overflow-y:hidden;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar{
      display: none;
    }
    .nav-item {
      //margin:0;
      //padding:0;
      list-style:none;

      .nav-link{
        white-space: nowrap
      }
    }
  }
}


//.menu-category  ul li a {
//  margin:0;
//  padding:17px 15px;
//  display:block;
//  background-color:#FFFFFF;
//}

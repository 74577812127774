
.counter {

  .counter-count{
    font-size: $h1-font-size;
    font-weight: bold;
  }

  .counter-item {
    @extend .justify-content-center;
    text-align: center;
    display: flex;

    &.counter-item-card{
      //@extend .card;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      //background-color: #fff;
      background-clip: border-box;
      //border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .counter-item-header{
      @extend .card-header;
    }
    .counter-item-footer{
      @extend .card-footer;
    }

    .counter-item-body{
      @extend .card-body;
      @extend .align-self-center;
    }

    .counter-item-icon{
      font-size: 4.5rem;
      line-height: 4.5rem;
      @extend .mb-4;
    }

    .counter-item-count{
      @extend .h1;
      @extend .mb-2;
      line-height: 100%;
      font-weight: normal;
    }

    .counter-item-label {

    }
  }

  &.counter-item-shadow-sm{
    .counter-item{
      @extend .shadow;
      @extend .shadow-sm;
    }
  }

  &.counter-item-shadow-lg{
    .counter-item{
      @extend .shadow;
      @extend .shadow-lg;
    }
  }



  .counter-item-light {
    @extend .bg-light;
    .counter-item-icon {
      color: $primary;
    }
    .counter-item-count {
      color: $primary;
    }
  }

  .counter-item-primary {
    @extend .bg-primary;
    .counter-item-header{
      color: $white;
      border-bottom-color: $white;
    }

    .counter-item-icon {
      color: $white;
    }
    .counter-item-count {
      color: $white;
    }
  }


}






:root {
  --theme-card-light-text-color: #{$light};
}


.card-light {

  @extend .border-none;

  //.card-subtitle{
  //  &.text-muted{
      //color: var(--theme-card-primary-text-color, #{$light}) !important;
    //}
  //}
  //.card-title{
  //  .h4{
  //    color: var(--theme-card-primary-text-color, #{$light});
  //  }
  //}
  //.card-body{
  //  color: var(--theme-card-primary-text-color, #{$light});
  //  a{
  //    color: var(--theme-card-primary-text-color, #{$light});
  //  }
  //}

  background-color: var(--bs-light, #{$light});
  * {

  }

  .card-foorter{

  }
  .btn-card-light{
    //border: none;

    @extend .btn-primary;
  }
}
:root {
  --theme-section-dark-text-color: #{$white};
  --theme-section-dark-background:  #{$dark};
}

.section-dark {
  background: var(--theme-section-dark-background, $dark);

  color: var(--theme-section-dark-text-color);

  .text-muted{
    color: var(--theme-section-dark-text-color) !important;
  }
}
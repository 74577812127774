@import 'swiper/css/bundle';


.swiper-slide {
  height: auto;
}



.swiper-hover{

  .swiper-button-prev{
    display: none;
  }

  .swiper-button-next{
    display: none;
  }

  &:hover{
    .swiper-button-prev {
      display: flex;
    }

    .swiper-button-next{
      display: flex;
    }
  }
}

@import "@grubersjoe/slide-menu/src/styles/slide-menu";

$color-primary: $gray-300;
$color-danger: hsl(356, 62%, 38%);
//
//main {
//
//  display: block; // IE11
//  width: 90%;
//  margin: 2.5rem auto;
//
//  .btn {
//    width: 100%;
//    display: block;
//    margin: 0 0.25rem 0.75rem 0;
//    background-color: lighten($color-primary, 8%);
//    border-radius: 0.2rem;
//    //font-size: 70%;
//    letter-spacing: 0.03em;
//    text-transform: uppercase;
//  }
//
//  @media (min-width: 768px) {
//    max-width: 40rem;
//
//    .btn {
//      width: auto;
//      display: inline-block;
//    }
//  }
//
//  #events {
//    font-family: monospace;
//    //font-size: 80%;
//  }
//}

.slide-menu {
  z-index: 10000;
  width: 340px;
  background-color: $color-primary;

  &,
  a {
    //font-size: 0.8rem;
    color: #fff;
  }

  a {
    //padding: 0.9rem 1.5rem;
    //border-bottom: solid 1px darken($color-primary, 8%);
    text-decoration: none;
  }

  .controls {
    background-color: var(--bs-primary-light);
    display: flex;
    .controls-left{

    }
    .controls-right{
      text-align: right;
    }
  }

  .btn {
    padding: 0px;
  }
  //  padding: 0.75rem 1.5rem;
  //  flex: 1 0 auto;
  //  //font-size: 90%;
  //  color: #fff;
  //  //background-color: darken($color-primary, 6%);
  //  text-transform: uppercase;
  //
  //  &:first-of-type {
  //    text-align: left;
  //
  //    &:before {
  //      content: '⮜';
  //      margin-right: 0.5rem;
  //    }
  //  }
  //
  //  &:last-of-type {
  //    text-align: right;
  //
  //    &:before {
  //      content: '';
  //      margin-right: 0.5rem;
  //    }
  //  }
  //}

  //.slide-menu__backlink {
  //  text-transform: uppercase;
  //  font-weight: bold;
  //}
}



.slide-menu {
  z-index: 1300;
  background-color: var(--bs-light, $light);

  .controls {
    @extend .border-bottom;
    padding: 0.75rem 2rem;
    margin-bottom: 1.5rem;

    a {
      color: var(--bs-body-color, $body-color);
    }
  }

  .with-childs {
    &:after{
      content: '\F285';
      font-family: 'bootstrap-icons';
      position: absolute;
      right: 2rem;
    }
  }

  .sub-menu {
    .slide-menu__backlink {
      background-color: var(--bs-gray-300);
      margin-top: -1.5rem;
      margin-bottom: 1.25rem;

      &:before{
        content: '\F284';
        font-family: 'bootstrap-icons';
        //position: absolute;
        //right: 10px;
      }
    }
  }

  .menu-item{
    a {
      color: var(--bs-body-color, $body-color);
      text-decoration: none;
      padding: 0.75rem 2rem;
    }

    &.menu-item-active > a{
      font-weight: bold;
    }

    &.menu-item-current{
      font-weight: bold;
    }
  }

  .social-icons {
    a {
      color: var(--bs-body-color, $body-color);
    }
  }
}

.slide-menu-overlay {
  z-index: 1200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: all 0.25s ease-out;

  display: none;
  opacity: 0;

  &.show {
    display: block;
    background-color: rgba(0,0,0,0.75);
    opacity: 1;
    transition: all 0.25s ease-out;


  }
}